.sidebar h6 {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  word-spacing: 2px;
  margin-left: -20px;
  margin-right: 20px;
  font-size: 18px;
  color: #14202b;
  opacity: 0.9;
}
.booking {
  width: 1102px;
  height: 466px;
  background: url("../../images/booking-bg.jpg");
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.search_container {
  display: flex;
  margin-top: 40px;
  max-width: 96vw;
}
@media screen and (max-width: 600px) {
  .reservation {
    flex-direction: column;
  }
  .sidebar h6 {
    display: none;
  }
  .search-fields {
    margin-bottom: 30px;
  }
  .booking {
    display: none;
  }
  .search-fields {
    margin-bottom: 30px;
  }
  .booking {
    max-width: 100vw;
    padding: 0;
    height: 100vh;
    background-position: right;
  }
  .search_container {
    flex-direction: column;
    height: 80vh;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 321px) {
  .search_container {
    margin-bottom: 50px;
  }
  .reservation {
    width: 90% !important;
  }
}
.reservation {
  display: flex;
  background: rgba(0, 0, 0, 0.55);
  justify-content: space-around;
  align-items: flex-end;
  width: 90%;
  padding: 40px 0 14px 0;
}

@media screen and (min-width: 600px) {
  .mobile_title {
    display: none;
  }
}

.search-fields {
  display: flex;
  flex-direction: column;
}

.search-button {
  background-color: #073762;
  color: white;
  font-family: Lato;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  width: 158px;
  border-radius: 8px;
  height: 59px;
  border: none;
  margin-bottom: 2px;
  cursor: pointer;
}

.inputs {
  height: 59px;
  width: 170px;
  border-radius: 8px;
  border: none;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  color: #444444;
  padding-left: 50px;
  background: none;
  background: url(/src/components/images/calendar.svg);
  background-position-y: 14px;
  background-position-x: 14px;
  background-color: white;
  background-repeat: no-repeat;
}

.inputs::-webkit-calendar-picker-indicator {
  background: none;

  background-image: url(/src/components/images/arrow.svg);
  background-size: 25%;
  background-position-y: 50%;
  cursor: pointer;
  padding-left: 10px;
}
.labels {
  color: white;
  font-family: Josefin Sans;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 10px;
}

.nights {
  background-image: url(/src/components/images/arrow.svg);
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 60%;
  padding-left: 95px;
  width: 222px;
  height: 59px;
  border-radius: 4px;
  border: none;
  font-size: 20px;
  line-height: 24px;
  color: #444444;
}
