.rooms-container {
  padding: 0 20px;
  margin: 0 20px;
  display: flex;
  justify-content: flex-start;
}

.img-container {
  width: 26rem;
  padding: 0 1rem;
  height: 18rem;
  position: relative;
  top: 30px;
}

.selector {
  display: flex;
  justify-content: center;
  align-items: center;
}

.selector-item {
  padding: 10px;
  margin: 10px 3px 10px 3px;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 20px;
}

.items{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80px;
}

@media screen and (max-width: 600px) {

}